@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Space Grotesk", sans-serif;
}

.blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: 1;
  height: 110%;
  width: 105%;
  left: -2.5%;
  top: -5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Marketing Page  */

.marketing-btn-gradient {
  background: linear-gradient(
    108.58deg,
    #6f02fa 27.97%,
    rgba(250, 1, 255, 0.2) 96.43%
  );
  text-align: center;
  display: flex;
  justify-content: center;
}

.marketing-text-gradient {
  background: linear-gradient(109.88deg, #ffffff 34.24%, #760aff 124.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.marketing-bg-gradient {
  background: linear-gradient(#170034, #530578);
}

.coming-soon {
  background-color: #fa01ff;
  color: #400463;
}

.pricing-section {
}

.table-section {
  scroll-snap-type: x mandatory;
  scroll-snap-points-x: repeat(200px);
}

.table-body tr {
  background-color: #26013c;
}

.table-column-parent {
  min-width: 250px;
  scroll-snap-align: start;
}

.table-container {
  background-color: #26013c;
}

.table-alt.table-container {
  background-color: #3a0259;
}

.table-alt th {
  padding-top: 30px;
}

.table-container tbody tr {
  height: 74px;
}

.mentor-access {
  color: #fa01ff;
}

.marketing-footer {
  background-color: #26013b;
}

.marketing-nav {
  background-color: transparent;
}

.mktg-logo {
  width: 40px;
}

.mktg-logo path {
  fill: #fff;
}

html {
  scroll-behavior: smooth !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

.mktg-input-ctn input {
  height: 40px;
  color: #fff;
}

.loom-iframe iframe {
  border-radius: 6px;
}

.survey-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.survey-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.blur-overlay {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(01, 01, 01, 0.4);
  backdrop-filter: blur(10px);
}

.comment-paragraph-text a {
  color: #760aff;
  font-weight: 600;
}

.discussion-single .mentions__suggestions__list {
  background-color: white;
  font-size: 14px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
}

.discussion-single .mentions__suggestions__item__display {
  padding: 12px;
}

.discussion-single .mentions {
  margin: 1em 0;
}

.discussion-single .mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}

.discussion-single .mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}

.discussion-single .mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
  outline: none !important;
  box-shadow: none !important;
}

.discussion-single .mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 12pt;
  font-family: "Space Grotesk", Arial, sans-serif;
}

.discussion-single .mentions--multiLine .mentions__highlighter {
  padding: 0 0.5rem;
  border: 0;
  font-size: 12pt;
  font-family: "Space Grotesk", Arial, sans-serif;
}

.discussion-single .mentions--multiLine .mentions__input {
  padding: 0 0.5rem;
  outline: 0;
  border: 0;
  font-size: 12pt;
  font-family: "Space Grotesk", Arial, sans-serif;
  outline: none !important;
  box-shadow: none !important;
}

.discussion-single .mentions__suggestions__list {
  background-color: white;
  font-size: 14px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
}

.discussion-single .mentions__suggestions__item {
  padding: 5px 15px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
}

.discussion-single .mentions__suggestions__item--focused {
  background-color: transparent;
}

.discussion-single .mentions__mention {
  position: relative;
  z-index: 1;
  color: #d709ee;
  pointer-events: none;
}

.emoji__button {
  position: absolute;
  cursor: pointer;
  padding: 1rem 0rem;
  top: 0;
  right: 0;
  background-color: white;
  border: 0;
}
.emoji__picker {
  position: absolute;
  bottom: 10px;
  right: 0;
  float: right;
  margin-left: 200px;
}

.discussion-single .comment-input-container {
  outline: none;
  border: none;
}

@media (max-width: 1023px) {
  .cta-buttons {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 720px) {
  .get-started-button {
    margin-top: 12px;
  }
}

#__next {
  height: 100%;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

@media (max-width: 720px) {
  .desktop-menu {
    display: none;
  }
}
